import styled from 'styled-components';
import ConversorMoeda from './ConversorMoeda';
import { devices } from '../helpers/devices';
import Table from './Table';

const Section = styled.section`
    width: 100%;
    background: ${props => props.theme.bgColor1};
    display: flex;
    flex-direction: column;
    align-items: center;
    `;

const ConversorDescricao = styled.div`
    color: ${props => props.theme.white1};
    text-align: center;

    & > h2 {
        font-weight: bold;
        font-size: 1.875rem;
    }

    @media ${devices.laptop} {
        margin: 1.5rem 2rem 0 2rem;
    }
    `;

const ConversorSection = (): JSX.Element => {

    return (
        <Section>
            <Table />
            <ConversorDescricao>
                <h2>Conversor de Moeda</h2>
                <p>Para saber a cotação da moeda desejada basta clicar na bandeira e selecionar o país desejado</p>
            </ConversorDescricao>
            <ConversorMoeda />
        </Section>
    )
}

export default ConversorSection;