import Header from '../components/Header';
import MainSection from '../components/MainSection';
import ConversorSection from '../components/ConversorSection';
import CertificadoSection from '../components/CertificadoSection';
import ServicosSection from '../components/ServicosSection';
import CardsSection from '../components/CardsSection';
import Footer from '../components/Footer';

const Landing = (): JSX.Element => (
        <>
            <Header />
            <MainSection fadeDown={false} /> 
            <ServicosSection />
            {
                <ConversorSection />
            }
            <CertificadoSection />
            <CardsSection />
            <Footer />
        </>
);

export default Landing;