import { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import Pais from '../interfaces/Pais';
import { devices, size } from '../helpers/devices';
import useWindowSize from '../helpers/useWindowSize';

const Dropdown = styled.div<{ show: boolean }>`
    display: ${props => props.show ? "grid" : "none"};
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 10fr;
    position: absolute;
    top: -28px;
    left: -59px;
    border-radius: 10px;
    background: ${props => props.theme.gray2};
    box-shadow: 1px 1px 3px ${props => props.theme.gray1};
    z-index: 1;
    padding: .8rem 1.2rem;

    @media ${devices.tablet} {
        left: -58px;
        padding: 1rem 3.9rem;
    }

    @media ${devices.mobileL} {
        left: -55px;
    }

    @media ${devices.mobileM} {
        left: -58px;
        padding: 1rem 2rem;
    }
    `;

const Div = styled.div`
    grid-row-start: 1;
    grid-column-start: 1;
    `;

const CountryContainer = styled.div<{ maxWidth: number }>`
    position: relative;
    grid-column-start: 1;
    grid-row-start: 2;

    @media ${devices.tablet} {
        max-width: ${props => props.maxWidth}px;
        overflow-x: hidden;
    }
    `;

const CountryCarousel = styled.div`
    display: grid;
    grid-template-columns: repeat(4, minmax(80px, 1fr));
    grid-template-rows: auto;
    grid-gap: .2rem;

    @media ${devices.tablet} {
        display: flex;
        transition: transform 300ms ease;
        grid-gap: 0;
    }
    `;

const CountryButton = styled.button`
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    `;

const CloseButton = styled.button`
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    border-radius: 0 10px 0 50%;
    outline: none;
    cursor: pointer;
    background: ${props => props.theme.white1};
    color: ${props => props.theme.gray1};
    font-weight: bold;
    padding: .4rem .5rem;
    `;

const LeftButton = styled.button`
    display: none;

    @media ${devices.tablet} {
        display: block;
        position: absolute;
        top: 40%;
        border: none;
        border-radius: 0 10px 10px 0;
        outline: none;
        background: ${props => props.theme.white1};
        color: ${props => props.theme.gray1};
        cursor: pointer;
        padding: .4rem .4rem;
    }
    `;

const RightButton = styled(LeftButton)`
    @media ${devices.tablet} {
        right: 0;
        border-radius: 10px 0 0 10px;
    }
    `;

const Flag = styled.img`
    width: 50px;
    height: 50px;
    transition: transform 100ms ease;

    &:hover {
        transform: scale(1.1);
    }

    @media ${devices.tablet} {
        width: 80px;
        height: 80px;
    }
    `;

interface Props {
    show: boolean,
    closeDropdown: Function,
    switchCountry: Function,
    id: number,
    paises: Pais[],
    itemsToShow: number;
}

const CountryDropdown = ({ show, closeDropdown, id, paises, switchCountry, itemsToShow }: Props): JSX.Element => {
    const [carouselPosition, setCarouselPosition] = useState<number>(0);
    const carouselRef = useRef<HTMLDivElement>(null);
    const { width } = useWindowSize();
    // CONSTANTS
    const itemSize = 80;
    const translateSteps = itemSize * itemsToShow;
    const lastItemPosition = -(Math.ceil(paises.length / itemsToShow) * translateSteps) + translateSteps;

    // Controla o click em uma bandeira de país
    const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, key: string): void => {
        event.preventDefault();
        switchCountry(id, key);
    };

    // Determina se o carousel pode prosseguir sem perder de vista os seus items
    const canContinue = (): boolean => {
        if (Math.abs(carouselPosition) + translateSteps > Math.abs(lastItemPosition)) {
            return false;
        }
        return true;
    };

    // Controla o movimento do carousel
    const handleCarousel = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, isLeft: boolean): void => {
        event.preventDefault();
        if (isLeft) {
            if (carouselPosition !== 0) {
                setCarouselPosition(carouselPosition + translateSteps);
            } else {
                setCarouselPosition(lastItemPosition);
            }
        } else {
            if (canContinue()) {
                setCarouselPosition(carouselPosition - translateSteps);
            } else {
                setCarouselPosition(0);
            }
        }
    };

    // Previne com que o usuário quebre o grid ao redimensionar a janela
    useEffect(() => {
        if (width > Number(size.tablet.replace('px', ''))) {
            setCarouselPosition(0);
        }
    }, [width]);

    // Atualiza a posição do carousel quando a variável muda
    useEffect(() => {
        if (carouselRef != null) {
            carouselRef.current!.style.transform = `translateX(${carouselPosition}px)`;
        }
    }, [carouselPosition]);

    return (
        <Dropdown show={show} onMouseLeave={() => closeDropdown(id) }>
            <Div>
                <CloseButton onClick=
                {event => {
                    event.preventDefault();
                    closeDropdown(id);
                }}>
                    X
                </CloseButton>
            </Div>
            <CountryContainer maxWidth={translateSteps}>
                <CountryCarousel ref={carouselRef}>
                    {paises.map(item => (
                        <CountryButton onClick={event => handleClick(event, item.moeda)} key={item.moeda}>
                            <Flag loading="lazy" id={item.moeda} src={item.bandeiraImg} alt={item.bandeira} />
                        </CountryButton>
                        ))
                    }
                </CountryCarousel>
            </CountryContainer>
            <LeftButton onClick={event => handleCarousel(event, true)}>&#10094;</LeftButton>
            <RightButton onClick={event => handleCarousel(event, false)}>&#10095;</RightButton>
        </Dropdown>
    )
};

export default CountryDropdown;