import { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { devices } from '../helpers/devices';
import paises_temp from '../helpers/paises_temp';
import Pais from '../interfaces/Pais';
import CountryDropdown from './CountryDropdown';
// import Chart from '../components/Chart';
import axios from 'axios';

const StyledConversorMoeda = styled.div`
    margin: 2rem 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @media ${devices.laptop} {
        position: relative;
        grid-template-columns: none;
        grid-template-rows: repeat(2, minmax(10px, 1fr));
        grid-row-gap: 20px;
    }
    `;

const ConversorLeft = styled.form`
    grid-column-start: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    color: ${props => props.theme.white1};

    & label {
        padding-left: 10px;
        margin-bottom: .3rem;
    }

    @media ${devices.laptop} {
        grid-row-start: 1;
        grid-column-start: 1;
        grid-column-end: 2;
        justify-self: center;
    }

    @media ${devices.mobileL} {
        position: relative;
        left: 15px;
    }

    @media ${devices.mobileM} {
        left: 45px;
        max-width: 270px;
    }
    `;

const InputContainer = styled.span`
    position: relative;
    margin-bottom: 1rem;
    `;

const Input = styled.input`
    padding: 1rem .4rem 1rem 1.8rem;
    background-color: ${props => props.theme.gray2};
    color: ${props => props.theme.white1};
    border: none;
    outline: none;
    border-right: 5px solid ${props => props.theme.white1};
    border-radius: 10px 25px 25px 10px;
    font-size: 1.2rem;
    appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        appearance: none;
        margin: 0;
    }

    @media ${devices.mobileM} {
        width: 90%;
    }
    `;

const RadioContainer = styled.div`
    display: flex;
    margin-bottom: .3rem;
    margin-left: .5rem;
`;

const Button = styled.button`
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    `;

const Flag = styled.img`
    position: absolute;
    width: 80px;
    height: 80px;
    top: -12px;
    left: -60px;
    transition: transform 100ms ease;

    &:hover {
        transform: scale(1.1);
    }
    `;

const ConversorRight = styled.div`
    grid-column-start: 2;
    display: flex;
    align-items: center;
    justify-content: center;

    @media ${devices.laptop} {
        grid-row-start: 2;
        grid-column-start: 1;
        grid-column-end: 2;
    }
    `;

const DownButton = styled.button`
    height: 25px;
    width: 25px;
    transform: rotate(-90deg);
    position: absolute;
    left: -8px;
    bottom: -12px;
    font-size: 1rem;
    border: none;
    outline: none;
    background: ${props => props.theme.white1};
    color: ${props => props.theme.gray2};
    padding: .2rem .2rem;
    border-radius: 50%;
    box-shadow: -1px 1px 2px ${props => props.theme.gray2};
    cursor: pointer;

    &:hover {
        transform: rotate(-90deg) translateX(-1px);
    }
    `;

const DisabledText = styled.span`
    opacity: .6;
    text-decoration: line-through;
    `;

interface ButtonProps { 
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
}

const ConversorMoeda = (): JSX.Element => {
    const paises: Pais[] = paises_temp;
    const [inputPaisPrimario, setInputPaisPrimario] = useState<string>('0');
    const [inputPaisSecundario, setInputPaisSecundario] = useState<string>('0');
    const [paisPrimario, setPaisPrimario] = useState<Pais>(paises[0]);
    const [paisSecundario, setPaisSecundario] = useState<Pais>(paises[1]);
    const [showDropdown0, setShowDropdown0] = useState<boolean>(false);
    const [showDropdown1, setShowDropdown1] = useState<boolean>(false);
    const [checkboxTurismoDisabled, setCheckboxTurismoDisabled] = useState<boolean>(false);
    const [turismoEnabled, setTurismoEnabled] = useState<boolean>(false);
    const [firstInputDisabled, setFirstInputDisabled] = useState<boolean>(false);

    const handleDropdown = ({ event, id }: ButtonProps): void => {
        event.preventDefault();
        switch (id) {
            case 0:
                if (showDropdown1) {
                    setShowDropdown1(false);
                }
                setShowDropdown0(!showDropdown0);
                setFirstInputDisabled(!firstInputDisabled);
                break;
            case 1:
                if (showDropdown0) {
                    setShowDropdown0(false);
                }
                setShowDropdown1(!showDropdown1);
                setFirstInputDisabled(!firstInputDisabled);
                break;
        }
    };

    const closeDropdown = (id: number): void => {
        switch (id) {
            case 0:
                setShowDropdown0(false);
                setFirstInputDisabled(!firstInputDisabled);
                break;
            case 1:
                setShowDropdown1(false);
                setFirstInputDisabled(!firstInputDisabled);
                break;
        }
    };

    const switchCountry = (id: number, key: string): void => {
        if (id === 0) {
            // Procura o país selecionado na lista de países
            for (let i = 0; i < paises.length; i++) {
                if (paises[i].moeda === key) {
                    // Se o país selecionado é o mesmo do outro, apenas faz a troca de lugar
                    if (key === paisSecundario.moeda) {
                        setPaisSecundario(paisPrimario);
                    }
                    // Ao achar o país selecionado, efetua a mudança
                    setPaisPrimario(paises[i]);
                }
            }
            setShowDropdown0(false);
        } else {
            // Procura o país selecionado na lista de países
            for (let i = 0; i < paises.length; i++) {
                if (paises[i].moeda === key) {
                    // Se o país selecionado é o mesmo do outro, apenas faz a troca de lugar
                    if (key === paisPrimario.moeda) {
                        setPaisPrimario(paisSecundario);
                    }
                    // Ao achar o país selecionado, efetua a mudança
                    setPaisSecundario(paises[i]);
                }
            }
            setShowDropdown1(false);
        }
    }

    const fetchConversion = (valorString: string) => {
        // if (Number(valorString) <= 0) {
        //     setInputPaisPrimario('0');
        //     setInputPaisSecundario('0');
        // } else {
            const codigoPrimario = turismoEnabled && paisPrimario.moeda === "Real" ? paisPrimario.codigoTurismo : paisPrimario.codigo;
            const codigoSecundario = turismoEnabled && paisSecundario.moeda === "Real" ? paisSecundario.codigoTurismo : paisSecundario.codigo;
            const url = `https://api-service.dascam.com.br/cotacao/moeda?codigoInicio=${codigoPrimario}&codigoFim=${codigoSecundario}&valor=${valorString}`
            // const url = `https://3409-187-101-186-253.ngrok.io/cotacao/moeda?codigoInicio=${codigoPrimario}&codigoFim=${codigoSecundario}&valor=${valorString}`
            const urlTurismo = `${url}&tipo=turismo`;

            axios
                .get(turismoEnabled ? urlTurismo : url)
                .then(response => {
                    setInputPaisSecundario(response.data.data.valor);
                }).catch(error => {
                    console.error(error);
                });
        
    };

    // Se o país escolhido não for 'Brasil', desativa o checkbox de 'Moeda Turismo'
    useEffect(() => {
        if (paisPrimario.moeda === "Real" && paisSecundario.moeda === "Dólar") {
            setCheckboxTurismoDisabled(false);
        }else if (paisPrimario.moeda === "Dólar" && paisSecundario.moeda === "Real") {
            setCheckboxTurismoDisabled(false);
        } else {
            setCheckboxTurismoDisabled(true);
        }
    }, [paisPrimario, paisSecundario]);

    // Ao escolher um país, atualiza a taxa e os inputs
    useEffect(() => {
        setInputPaisPrimario('0');
        setInputPaisSecundario('0');
    }, [paisPrimario, paisSecundario]);

    useEffect(() => {
        if (checkboxTurismoDisabled) {
            setTurismoEnabled(false);
        }
    }, [checkboxTurismoDisabled]);

    useEffect(() => {
        setInputPaisPrimario('0');
        setInputPaisSecundario('0');
    }, [turismoEnabled]);

    useEffect(() => {
        if (Number(inputPaisPrimario) <= 0){
            setInputPaisSecundario("0");
        } else {
            fetchConversion(inputPaisPrimario)
        }
    }, [inputPaisPrimario]);

    useEffect(() => {
        if (paisPrimario.moeda !== "Real"){
            setPaisSecundario(paises[0]);
        }
    }, [paisPrimario]);

    useEffect(() => {
        if (paisSecundario.moeda !== "Real" && paisPrimario.moeda !== "Real"){
            setPaisPrimario(paises[0]);
        }
    }, [paisSecundario]);

    return (
        <StyledConversorMoeda>
            <ConversorLeft>
                <label htmlFor="valorMoedaPrimaria"><strong>De: </strong>{paisPrimario.moeda}</label>
                <InputContainer>
                    <Button onClick={event => handleDropdown({ event, id: 0 })}>
                        <Flag width="80px" height="80px" src={paisPrimario.bandeiraImg} alt={paisPrimario.bandeira}/>
                    </Button>
                    <DownButton onClick={event => handleDropdown({ event, id: 0 })}>&#10094;</DownButton>
                    <CountryDropdown
                        paises={paises}
                        id={0}
                        show={showDropdown0}
                        closeDropdown={closeDropdown}
                        switchCountry={switchCountry}
                        itemsToShow={3}
                        />
                    <Input
                        onChange={event => setInputPaisPrimario(event.target.value)}
                        onBlur={() => {
                            if (Number(inputPaisPrimario) <= 0){
                                setInputPaisPrimario("0");
                            }
                        }}
                        onFocus={() => {
                            if (Number(inputPaisPrimario) <= 0){
                                setInputPaisPrimario("");
                            }
                        }}
                        type="number"
                        min="0"
                        step="0.00"
                        name="valorMoedaPrimaria"
                        id="valorMoedaPrimaria"
                        value={inputPaisPrimario}
                        disabled={firstInputDisabled}
                    />
                </InputContainer>
                <label htmlFor="valorMoedaSecundaria"><strong>Para: </strong>{paisSecundario.moeda}</label>
                <InputContainer>
                    <Button onClick={event => handleDropdown({ event, id: 1 })}>
                        <Flag width="80px" height="80px" src={paisSecundario.bandeiraImg} alt={paisSecundario.bandeira}/>
                    </Button>
                    <DownButton onClick={event => handleDropdown({ event, id: 1 })}>&#10094;</DownButton>
                    <CountryDropdown
                        paises={paises}
                        id={1}
                        show={showDropdown1}
                        closeDropdown={closeDropdown}
                        switchCountry={switchCountry}
                        itemsToShow={3}
                    />
                    <Input
                        type="number"
                        name="valorMoedaSecundaria"
                        id="valorMoedaSecundaria"
                        value={inputPaisSecundario}
                        readOnly
                    />
                </InputContainer>
                <RadioContainer>
                    <input
                        value="Moeda de Turismo"
                        type="radio"
                        name="tipoMoeda"
                        id="tipoMoeda1"
                        checked={turismoEnabled}
                        disabled={checkboxTurismoDisabled}
                        onChange={() => setTurismoEnabled(!turismoEnabled)}
                        />
                            <label htmlFor="tipoMoeda1">
                                {checkboxTurismoDisabled
                                ?
                                    <DisabledText>Moeda de Turismo</DisabledText>
                                :
                                    "Moeda de Turismo"
                                }
                            </label>
                </RadioContainer>
                <RadioContainer>
                    <input
                        checked={!turismoEnabled}
                        onChange={() => setTurismoEnabled(!turismoEnabled)}
                        type="radio"
                        value="Moeda Comercial"
                        name="tipoMoeda"
                        id="tipoMoeda2"
                        />
                            <label htmlFor="tipoMoeda2">Moeda Comercial</label>
                </RadioContainer>
            </ConversorLeft>
            <ConversorRight>
                {//!turismoEnabled ?
                 //   <Chart paisPrimario={paisPrimario} paisSecundario={paisSecundario} turismoEnabled={turismoEnabled}/>
                 //   : null 
               }
            </ConversorRight>
        </StyledConversorMoeda>
    )
}

export default ConversorMoeda;