import { useState, useEffect } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { devices } from '../helpers/devices';
import { Cotacao } from '../interfaces/Cotacao';
import axios from 'axios';

const StyledTable = styled.table<{ showMore?: boolean }>`
    position: relative;
    top: -5.5rem;
    background-color: ${props => props.theme.gray2};
    border-radius: 15px;
    border-collapse: collapse;

    & tr:first-child {
        border-bottom: 1px solid ${props => props.theme.white3};
    }

    & tr:nth-child(n+6) {
        display: ${props => props.showMore ? "default" : "none"};
    }

    & tr > th, & tr > td {
        color: ${props => props.theme.white1};
        padding: .5rem 1.5rem;
    }

    & tr > td:first-child, & tr > th:first-child {
        text-align: left;
    }
    `;

const HeaderRightAlign = styled.th`
    text-align: right;
    `;

const HeaderLeftAlign = styled(HeaderRightAlign)`
    text-align: left;
    `;

const RightBorderWhite = styled.td`
    border-right: 1px solid ${props => props.theme.white1};
    font-weight: bold;
    `;

const NumberRightAlign = styled.td`
    text-align: right;
    `;

const Gain = styled.td`
    color: ${props => props.theme.confirmColor} !important;
    text-align: right;
    `;

const Loss = styled.td`
    color: ${props => props.theme.denyColor} !important;
    text-align: right;
    `;

const RelativeDiv = styled.div`
    position: relative;

    @media ${devices.laptop} {
        display: none;
    }
    `;

const Spinny = keyframes`
    0% { transform: rotate(0deg) };
    100% { transform: rotate(360deg) };
    `;

const SpinnyMixin = css`
    animation: ${Spinny} 900ms infinite;
    `;

const ButtonRefresh = styled.button<{ isLoading: boolean }>`
    position: absolute;
    right: -15px;
    top: -105px;
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 10px;
    background: ${props => props.theme.white1};
    box-shadow: 1px 1px 5px ${props => props.theme.gray1};
    outline: none;
    cursor: pointer;
    will-change: transform;
    transition: transform 150ms ease;

    & p {
        color: ${props => props.theme.gray2};
        font-size: 1.8rem;
        font-weight: bold;
        ${props => props.isLoading ? SpinnyMixin : null};
    }

    &:hover {
        transform: translateY(-4px);
    }
    `;

const ButtonShowMore = styled.button`
    position: absolute;
    right: 0;
    bottom: 50px;
    width: 100px;
    height: 40px;
    border: none;
    border-radius: 10px;
    background: ${props => props.theme.white1};
    box-shadow: 1px 1px 5px ${props => props.theme.gray1};
    outline: none;
    cursor: pointer;
    will-change: transform;
    transition: transform 150ms ease;

    & p {
        color: ${props => props.theme.gray2};
        font-size: 1rem;
        font-weight: bold;
    }

    &:hover {
        transform: translateY(-4px);
    }
    `;

const StyledTableLoading = styled(StyledTable)`
    width: 65%;
    height: 200px;

    &:before {
        content: '↻';
        font-size: 1.8rem;
        position: absolute;
        top: 46%;
        left: 49%;
        color: ${props => props.theme.white1};
        animation: ${Spinny} 900ms infinite;
        z-index: 5;
    }
    `;

const Table = (): JSX.Element => {
    const [movimento, setMovimento] = useState<Cotacao>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [showMore, setShowMore] = useState<boolean>(false);

    const fetchNewData = (): void => {
        setLoading(true);

        axios
            .get("https://api-service.dascam.com.br/cotacao/tabela")
            .then(response => {
                setMovimento(response.data.data);

                setTimeout(() => {
                    setLoading(false);
                }, 1000);
            });
    };

    useEffect(() => {
        fetchNewData();
        let intervalID = setInterval(() => fetchNewData(), 300000);

        return () => {
            clearInterval(intervalID);
        };
    }, []);

    if (movimento != null) {
        return (
            <RelativeDiv>
                <StyledTable showMore={showMore}>
                    <tbody>
                        <tr>
                            <HeaderLeftAlign>Código</HeaderLeftAlign>
                            <HeaderRightAlign>Compra</HeaderRightAlign>
                            <HeaderRightAlign>Venda</HeaderRightAlign>
                            <HeaderRightAlign>Máxima</HeaderRightAlign>
                            <HeaderRightAlign>Mínima</HeaderRightAlign>
                            <HeaderRightAlign>Var.</HeaderRightAlign>
                            <HeaderRightAlign>Var.%</HeaderRightAlign>
                            <th>Hora</th>
                        </tr>
                        {movimento.map((mov, index) => (
                            <tr key={index}>
                                <RightBorderWhite>{mov.codigoInicio}/{mov.codigoFim}</RightBorderWhite>
                                <NumberRightAlign>{mov.compra}</NumberRightAlign>
                                <NumberRightAlign>{mov.venda}</NumberRightAlign>
                                <NumberRightAlign>{mov.maxima}</NumberRightAlign>
                                <NumberRightAlign>{mov.minima}</NumberRightAlign>
                                { 
                                    Number(mov.variacao) > 0
                                    ? <Gain>{mov.variacao}</Gain>
                                    : <Loss>{mov.variacao}</Loss>
                                }
                                {
                                    Number(mov.variacaoPorcentagem) > 0
                                    ? <Gain>{mov.variacaoPorcentagem}%</Gain>
                                    : <Loss>{mov.variacaoPorcentagem}%</Loss>
                                }
                                <td>{mov.horario}</td>
                            </tr>
                        ))}
                    </tbody>
                </StyledTable>
                <ButtonRefresh
                    title="Atualizar tabela"
                    isLoading={loading}
                    onClick={() => fetchNewData()}
                    >
                    <p>&#8635;</p>
                </ButtonRefresh>
                <ButtonShowMore
                    title="Exibir"
                    onClick={() => setShowMore(!showMore)}
                    >
                    <p>{showMore === true ? "Ver menos!" : "Ver mais!"}</p>
                </ButtonShowMore>
            </RelativeDiv>
        )
    } else {
        return <StyledTableLoading></StyledTableLoading>;
    }
}

export default Table;