import Pais from "../interfaces/Pais"
import BandeiraBR from '../assets/bandeiras/bandeira_br.png';
import BandeiraUS from '../assets/bandeiras/bandeira_us.png';
import BandeiraJP from '../assets/bandeiras/bandeira_jp.png';
import BandeiraFR from '../assets/bandeiras/bandeira_fr.png';
import BandeiraAU from '../assets/bandeiras/bandeira_au.png';
import BandeiraCA from '../assets/bandeiras/bandeira_ca.png';
import BandeiraUK from '../assets/bandeiras/bandeira_uk.png';

const paises_temp: Pais[] = [
    { 
        nome: "Brasil",
        moeda: "Real",
        codigo: "BRL",
        codigoTurismo: "BRLT",
        bandeira: "Bandeira do Brasil",
        bandeiraImg: BandeiraBR,
    },
    { 
        nome: "Estados Unidos",
        moeda: "Dólar",
        codigo: "USD",
        bandeira: "Bandeira dos Estados Unidos",
        bandeiraImg: BandeiraUS,
    },
    { 
        nome: "França",
        moeda: "Euro",
        codigo: "EUR",
        bandeira: "Bandeira da França",
        bandeiraImg: BandeiraFR,
    },
    { 
        nome: "Japão",
        moeda: "Iene",
        codigo: "JPY",
        bandeira: "Bandeira do Japão",
        bandeiraImg: BandeiraJP,
    },
    { 
        nome: "Austrália",
        moeda: "Dólar Australiano",
        codigo: "AUD",
        bandeira: "Bandeira da Austrália",
        bandeiraImg: BandeiraAU,
    },
    { 
        nome: "Canadá",
        moeda: "Dólar Canadense",
        codigo: "CAD",
        bandeira: "Bandeira do Canadá",
        bandeiraImg: BandeiraCA,
    },
    { 
        nome: "Reino Unido",
        moeda: "Libra Esterlina",
        codigo: "GBP",
        bandeira: "Bandeira do Reino Unido",
        bandeiraImg: BandeiraUK,
    },
];

export default paises_temp;